.activities__img {
    width: 100%;
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
}
.activities__title {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
}
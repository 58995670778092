/* --{HERO}-- */
.hero {
    margin-top: 7rem;
    background-image: url('../../assets/img/hero/hero-img-mobile.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 90vh;
}
.hero__title {
    user-select: none;
    font-weight: bold;
    font-size: 7rem;
}
.hero__span {
    display: block; 
}
.hero__img {
    user-select: none;
    width: 120px;
    height: 120px;
}
.hero__red {
    color: #D00508;
}
.hero__blue {
    color: #00CDFA;
}
@media (min-width:768px) {
    .hero {
        background-image: url('../../assets/img/hero/hero-img.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 90vh;
    }
    .hero__span {
        display: inline;
    }
    .hero__title {
        font-weight: bold;
        font-size: 8rem;
    }
    .nosotros__logo-cano {
        height: 30rem;
    }
}
@media (min-width: 760px) and (max-width: 990px) {
    .hero {
        margin-top: 3rem;
    }
}
/* --{ACTIVITIES}-- */
.activities {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
/* --{NOSOTROS}-- */
.nosotros__main {
    padding-top: 5rem;
}
.nosotros__logo-cano {
    height: 20rem;
}
@media (min-width:992px) {
    .nosotros__logo-cano {
        height: 30rem;
    }
}
.contact {
    padding-top: 10rem;
    padding-bottom: 7rem;
}
.club-cano-map {
    padding: 0;
    border-radius: 50px;
    max-width: 100%;
    -webkit-box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.75);
}
.map {
    padding-bottom: 8rem;
}
@media (min-width:768px) {
    .padding__contact {
        padding-top: 1rem;
    }
}
.contact__card {
    padding: 3rem;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
    max-width: 30rem;
}
.link__social {
    text-decoration: none;
}
.img__social {
    height: 4rem;
}
.contact__horary {
}
.sport {
    padding-top: 12rem;
    padding-bottom: 10rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sport__card {
    background-color: rgba(255, 255, 255, 0.80);
    border-radius: 50px;
    padding: 3rem;
}
.sport__image {
    width: 100%;
    height: 20rem;
    object-fit: cover;
}
.hacete-socio {
    padding-top: 10rem;
    padding-bottom: 4rem;
}
.hacete-socio__title {
    color: #D00508;
    font-weight: 800;
    font-size: 5rem;
}
.download-inscription{
    padding-bottom: 8rem;
}
.comision__card {
    padding: 2rem;
}
.comision__img {
    width: 100%;
    border-radius: 100%;
}
.comision__position {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem;
}
.comision__name {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 1.2rem;
}
/* --{FOOTER}-- */
.footer {
    background: linear-gradient(to left, #00CDFA, #F23636);
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .footer h2 {
    color: #fff;
    font-size: 3.5rem;
    font-weight: bold;
  }
  .footer__text {
    text-decoration: none;
    font-style: italic;
    font-weight: bolder;
    font-size: 1.3rem;
    color: #ebebeb;
  }
  .footer__text:hover {
    text-decoration: underline;
  }
  .footer__social {
    height: 100px;
    width: 100px;
  }
  .footer__legal {
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
  }
  .footer__description {
    font-weight: lighter;
    font-size: 1rem;
    color: #fff;
  }
  .footer__hadar {
    text-decoration: none;
  }
  /* --{ALERT}-- */
  .custom-button {
    background: linear-gradient(to left, #00CDFA, #F23636) !important;
    font-weight: bold;
  }
/* --{BUBBLE}-- */
.bubble {
    padding: 3.5rem;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #f5ff2d;
    position: fixed;
    bottom: 20px;
    right: 20px;
}
.bubble__title {
    color: #000;
    font-size: 1.5rem;
    font-weight: 800;
}
/* --{NAVBAR}-- */
.navbar {
    background: linear-gradient(to left, #00CDFA, #F23636) !important;
  }
.navbar-toggler {
    padding: 1rem;
    background-color: #FF7269;
}
.navbar__title-red {
    color: #D00508;
}
.navbar__title-blue {
    color: #00CDFA;
}

@media (max-width:768px) {
    .offcanvas-title {
        color: #fff;
        font-size: 2rem;
        font-weight: bolder;
    }
    .offcanvas-header {
        background-color: #fd7070;
    }
    .offcanvas-body {
        background-image: url('../../assets/img/background/sun-tornado-bg.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .nav-link {
        font-family: sans-serif;
        margin: 0.5rem;
        color: #fff;
        font-weight: bold;
        font-size: 1.5rem;
        box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.1);
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
    }
    .navbar__titleOff {
        padding: 5px;
        border-radius: 20px;
        background-color: rgb(240, 126, 126);
        box-shadow: inset 0px 0px 18px 8px rgba(0,0,0,0.1);
    }
}

@media (min-width:768px) {
    .nav-link {
        color: #000;
        font-weight: bold;
        font-size: 1.5rem;
        color: #fff;
    }
    .navbar__bg {
        width: 2rem;
        border-radius: 50px;
        background-color: #fd7070;
        box-shadow: inset 0px 0px 22px 8px rgba(0,0,0,0.1),inset 0px 0px 90px 30px rgba(0,0,0,0.1);
    }
    .offcanvas-body {
        width: 40rem;
    }
    .offcanvas {
        flex-grow: 0 !important;
    }
}